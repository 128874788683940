<template>
  <!-- eslint-disable -->
  <nav
    :class="{ open }"
    class="relative px-6 py-6 flex justify-between items-center bg-gray-900 mb-12 lg:mb-32"
  >
    <div class="flex-grow"></div>
    <div class="lg:hidden">
      <div
        class="hamburger"
        @click="open = !open"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>>
    <div class="hidden text-sapphire-300 absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:flex lg:mx-auto lg:items-center lg:w-auto lg:space-x-9">
      <icon icon="ellipsis-v" />
      <a
        href="/"
        class="cursor-pointer hover:underline text-gray-300 hover:text-gray-100"
      >Home</a>
      <icon icon="ellipsis-v" />
      <a
        href="#about"
        v-smooth-scroll
        class="cursor-pointer hover:underline text-gray-300 hover:text-gray-100"
      >About</a>
      <icon icon="ellipsis-v" />
      <a
        href="#contact"
        v-smooth-scroll
        class="cursor-pointer hover:underline text-gray-300 hover:text-gray-100"
      >Contact</a>
      <icon icon="ellipsis-v" />
    </div>
    <span
      @click="goToRandomSite"
      class="hidden lg:inline-block py-2 px-6 bg-sapphire-500 hover:bg-sapphire-600 text-sm ring ring-cerise-900 ring-offset-2 ring-offset-gray-900 text-white font-bold rounded-l-xl rounded-t-xl transition-all duration-200"
    >surprise</span>
  </nav>
  <!-- eslint-enable -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import siteList from '@/assets/json/siteList.json';

export default defineComponent({
  data: () => ({ open: false }),
  methods: {
    goToRandomSite() {
      window.open(siteList[Math.floor(Math.random() * siteList.length)]);
    },
  },
});
</script>

<style lang="scss" scoped>
.hamburger {
  @apply w-8 flex-col gap-1 cursor-pointer z-50 hidden;

  span {
    @apply h-1 w-full block bg-white transition-transform duration-150 origin-right;
  }
}

.open {
  .hamburger {
    span:nth-of-type(2) {
      @apply transform scale-x-75;
    }

    span:nth-of-type(3) {
      @apply transform scale-x-50;
    }
  }
}
</style>
