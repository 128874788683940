<template>
  <!-- eslint-disable -->
  <div class="select-none overflow-x-hidden">
    <Navigation />
    <section class="pt-12 lg:pt-20 pb-12 md:pb-40 lg:pb-60 mx-auto">
      <div class="w-full max-w-6xl mx-auto px-4">
        <div class="flex flex-wrap -mx-4 flex-col-reverse md:flex-row">
          <div class="w-full lg:w-1/2 px-4 mt-5 sm:mt-0 sm:mb-8 md:mb-20 lg:mb-0 flex items-center">
            <div class="w-full">
              <div class="max-w-md mx-auto lg:mx-0">
                <h1>👋 Hey, I am <span class="text-transparent bg-clip-text bg-gradient-to-br from-sapphire-500 to-cerise">Xander!</span></h1>
              </div>
              <div class="max-w-md mx-auto lg:mx-0">
                <div class="mb-6">
                  <p>
                    A {{ age }} year old Full Stack Developer from 🇧🇪 Belgium.
                  </p>
                  <p>
                    Interested in <span class="text-code-nodejs">NodeJS</span>, <span class="text-code-vuejs">VueJS</span>, <span class="text-code-tailwindcss">tailwindCSS</span> and <span class="text-code-python">Python</span>.
                  </p>
                  <p class="rounded-md flex space-x-2 items-center text-gray-300 mt-3">
                    <span
                      class="h-3 w-3 rounded-full flex-shrink-0 bg-gray-200"
                      :class="user.data.discord_status"
                    ></span>
                    <span class="text-base truncate block">
                      {{ user.data.discord_status || 'offline' }}
                    </span>
                  </p>
                </div>
                <div class="text-center md:text-left">
                  <a
                    href="#about"
                    v-smooth-scroll
                    class="cursor-pointer inline-block mb-3 lg:mb-0 lg:mr-3 w-full lg:w-auto py-2 px-6 bg-cerise hover:bg-cerise-600 hover:scale-105 focus:bg-cerise-600 transform text-white font-semibold rounded-t-xl lg:rounded-r-none lg:rounded-l-xl transition duration-75"
                  >About Me</a>
                  <a
                    href="#contact"
                    v-smooth-scroll
                    class="cursor-pointer inline-block mb-3 lg:mb-0 lg:mr-3 w-full lg:w-auto py-2 px-6 border-2 border-sapphire-400 hover:border-sapphire-500 hover:scale-105 focus:border-gray-600 transform text-white font-semibold rounded-b-xl lg:rounded-l-none lg:rounded-r-xl transition duration-75"
                  >Contact me</a>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-1/2 px-4 flex items-center justify-center relative">
            <kinesis-container class="z-0 relative h-96 w-96">
              <kinesis-element
                class="-top-16 -left-24 absolute w-full"
                :strength="5"
              >
                <svg
                  viewBox="0 40 200 125"
                  xmlns="http://www.w3.org/2000/svg"
                  class="z-0 text-cerise fill-current"
                >
                  <path
                    d="M44.9,-32.1C56.8,-20.9,64.2,-2.3,60.1,12.9C56,28.1,40.4,39.8,23.5,47.6C6.5,55.3,-11.9,59.1,-30.8,53.9C-49.6,48.6,-69,34.4,-76.9,13.6C-84.9,-7.3,-81.4,-34.7,-66.7,-46.6C-52,-58.5,-26,-54.9,-4.8,-51.1C16.5,-47.3,33,-43.4,44.9,-32.1Z"
                    transform="translate(100 100)"
                  />
                </svg>
              </kinesis-element>
              <kinesis-element class="-bottom-12 -right-32 absolute w-full">
                <svg
                  viewBox="0 0 200 150"
                  xmlns="http://www.w3.org/2000/svg"
                  class="z-0 text-sapphire fill-current"
                >
                  <path
                    d="M47.7,-65.3C53.9,-51.6,45.8,-29.1,45.4,-11C45.1,7.2,52.6,20.9,49,29.2C45.3,37.5,30.5,40.2,17.8,41.8C5.1,43.4,-5.5,43.8,-17,41.7C-28.5,39.7,-40.9,35.2,-44.8,26.7C-48.6,18.2,-43.9,5.7,-39.9,-5.3C-35.8,-16.2,-32.5,-25.4,-25.9,-39.1C-19.4,-52.7,-9.7,-70.7,5.5,-77.3C20.7,-83.8,41.4,-78.9,47.7,-65.3Z"
                    transform="translate(100 100)"
                  />
                </svg>
              </kinesis-element>
              <kinesis-element
                :strength="15"
                type="depth"
              >
                <img
                  src="https://avatars.githubusercontent.com/u/36887752"
                  alt="Github profile picture"
                  class="absolute top-0 shadow-lg left-0 w-full max-w-lg object-cover rounded-3xl ring ring-cerise ring-offset-cerise"
                >
              </kinesis-element>
            </kinesis-container>
          </div>
        </div>
      </div>
    </section>

    <article
      id="about"
      class="mx-auto mb-24 lg:mb-48"
    >
      <section class="max-w-6xl mx-auto px-4 mb-20">
        <div class="flex flex-col lg:flex-row items-center gap-6 lg:gap-10">
          <div class="w-full lg:w-1/2 lg:mb-0">
            <div class="lg:mx-auto">
              <h2>A little more <span class="text-cerise">about me</span></h2>
              <p class="mb-3">
                Hey there, my name is Xander. I recently earned my bachelors
                degree in applied computer science at <a
                  href="https://www.howest.be"
                  class="text-sapphire-200"
                >Howest</a> and I am very much in love with
                VueJS and NodeJS.
              </p>
              <p class="mb-6">Currently working on personal projects.</p>
              <!-- eslint-disable max-len -->
              <ul class="font-bold">
                <li
                  v-for="item in skills"
                  :key="item"
                  class="flex mt-4 items-center"
                >
                  <span class="mr-3 w-5 h-5 bg-green-400 rounded-full flex justify-center items-center text-gray-800">
                    <icon
                      icon="check"
                      class="fa-xs"
                    />
                  </span>
                  <span>{{ item }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="w-full lg:w-1/2 flex space-x-4">
            <div class="mb-8 lg:mb-0 w-1/2">
              <kinesis-container>
                <kinesis-element
                  type="depth"
                  :strength="20"
                >
                  <div class="mb-4 md:mb-8 py-6 pl-6 pr-4 shadow-md rounded-t-xl rounded-bl-xl bg-gray-800">
                    <span class="mb-4 inline-block p-3 rounded-t-xl rounded-bl-xl bg-code-nodejs">
                      <programming-icon
                        icon='nodejs'
                        class="text-5xl text-black"
                      />
                    </span>
                    <h3 class="mb-2 text-xl sm:text-2xl font-bold font-heading text-white">NodeJS</h3>
                    <p class="text-gray-300 leading-loose">About 2,5 year(s)</p>
                  </div>
                </kinesis-element>
              </kinesis-container>
              <kinesis-container>
                <kinesis-element
                  type="depth"
                  :strength="20"
                >
                  <div class="mb-4 md:mb-8 py-6 pl-6 pr-4 shadow-md rounded-l-xl rounded-br-xl bg-gray-800">
                    <span class="mb-4 inline-block p-3 rounded-t-xl rounded-bl-xl bg-code-vuejs">
                      <programming-icon
                        icon='vuejs'
                        class="text-5xl text-black"
                      />
                    </span>
                    <h3 class="mb-2 text-xl sm:text-2xl font-bold font-heading text-white">VueJS</h3>
                    <p class="text-gray-300 leading-loose">About 1,5 year(s)</p>
                  </div>
                </kinesis-element>
              </kinesis-container>
            </div>
            <div class="w-1/2 lg:mt-20 md:px-4">
              <kinesis-container>
                <kinesis-element
                  type="depth"
                  :strength="20"
                >
                  <div class="mb-4 md:mb-8 py-6 pl-6 pr-4 shadow-md rounded-t-xl rounded-br-xl bg-gray-800">
                    <span class="mb-4 inline-block p-3 rounded-t-xl rounded-bl-xl bg-code-mongodb">
                      <programming-icon
                        icon='mongodb'
                        class="text-5xl text-black"
                      />
                    </span>
                    <h3 class="mb-2 text-xl sm:text-2xl font-bold font-heading text-white">MongoDB</h3>
                    <p class="text-gray-300 leading-loose">About 1 year(s)</p>
                  </div>
                </kinesis-element>
              </kinesis-container>
              <kinesis-container>
                <kinesis-element
                  type="depth"
                  :strength="20"
                >
                  <div class="mb-4 md:mb-8 py-6 pl-6 pr-4 shadow-md rounded-b-xl rounded-tr-xl bg-gray-800">
                    <span class="mb-4 inline-block p-3 rounded-t-xl rounded-bl-xl bg-code-tailwindcss">
                      <programming-icon
                        icon='tailwindcss'
                        class="text-5xl text-black"
                      />
                    </span>
                    <h3 class="mb-2 text-xl sm:text-2xl font-bold font-heading text-white">TailwindCSS</h3>
                    <p class="text-gray-300 leading-loose">About 1 year(s)</p>
                  </div>
                </kinesis-element>
              </kinesis-container>
            </div>
          </div>
        </div>
      </section>
      <TechStack class="mb-12 lg:mb-24" />
      <CurrentActivities :user="user.data" />
    </article>
    <article
      id="contact"
      class="mx-auto mb-16 lg:mb-24"
    >
      <div class="max-w-6xl mx-auto px-4">
        <h2 class="mb-10 text-center font-semibold">Contact <span class="text-cerise">me</span></h2>
        <section class="w-full max-w-2xl mx-auto mb-12 hidden">
          <form class="grid gap-4">
            <div class="grid gap-4 md:grid-cols-2">
              <input
                type="email"
                class="appearance-none block w-full py-3 px-4 leading-tight text-gray-700 bg-gray-50 focus:bg-white border border-gray-200 focus:border-gray-500 rounded-t-xl md:rounded-tr-none focus:outline-none fbc-has-badge fbc-UID_1"
                placeholder="Email"
              >
              <input
                type="text"
                class="appearance-none block w-full py-3 px-4 leading-tight text-gray-700 bg-gray-50 focus:bg-white border border-gray-200 focus:border-gray-500 md:rounded-tr-xl focus:outline-none"
                placeholder="Name"
              >
            </div>
            <textarea
              class="appearance-none block w-full py-3 px-4 leading-tight text-gray-700 bg-gray-50 focus:bg-white border border-gray-200 focus:border-gray-500 focus:outline-none"
              placeholder="Write something..."
              rows="5"
            />
            <button
              aria-label="Submit"
              class="inline-block w-full py-4 px-8 leading-none text-white bg-cerise hover:bg-cerise-600 font-semibold rounded-b-xl shadow"
              style="transform: none;"
            >
              Submit
            </button>
          </form>
        </section>
        <section class="flex flex-col lg:flex-row justify-center -mx-4">
          <a
            href="mailto:xander.denecker@gmail.com"
            class="cursor-pointer hover:underline px-4 flex items-center"
          >
            <icon
              icon="envelope"
              class="inline-block w-6 h-6 mr-3 text-sapphire-300"
            />
            xander.denecker@gmail.com
          </a>
          <a
            href="https://github.com/XanderD99"
            target="_blank"
            class="cursor-pointer hover:underline px-4 flex items-center"
          >
            <icon
              :icon="['fab', 'github']"
              class="inline-block w-6 h-6 mr-3 text-sapphire-300"
            />
            @XanderD99
          </a>
          <a
            href="https://www.instagram.com/denecker_xander/"
            target="_blank"
            class="cursor-pointer hover:underline px-4 flex items-center "
          >
            <icon
              :icon="['fab', 'instagram']"
              class="inline-block w-6 h-6 mr-3 text-sapphire-300"
            />
            @denecker_xander
          </a>

          <a
            href="https://www.linkedin.com/in/xanderdenecker/"
            target="_blank"
            class="cursor-pointer hover:underline px-4 flex items-center "
          >
            <icon
              :icon="['fab', 'linkedin']"
              class="inline-block w-6 h-6 mr-3 text-sapphire-300"
            />
            @xanderdenecker
          </a>
        </section>
      </div>
    </article>
    <Footer />
  </div>
  <!-- eslint-enable -->
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';

import Footer from '@/components/Footer.vue';
import TechStack from '@/components/about/techStack.vue';
import CurrentActivities from '@/components/about/currentActivities.vue';
import ProgrammingIcon from '@/components/programmingIcon.vue';
import Navigation from '@/components/Navigation.vue';
import { onBeforeRouteLeave } from 'vue-router';
import { Activity, LanyardData } from '@/types/lanyard';
import { useWebSocket } from '@vueuse/core';

const userId = process.env.USER_ID || '355084177085038592';

export default defineComponent({
  components: {
    Footer,
    TechStack,
    CurrentActivities,
    Navigation,
    ProgrammingIcon,
  },
  setup() {
    const birthday = new Date('11/24/1999');
    const ageDate = new Date(Date.now() - birthday.getTime());
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);

    const skills = [
      'JavaScript (Node & Client-Side)',
      'Python',
      'NoSQL (MongoDB & FaunaDB)',
      'SQL (Postgre & Sqlite)',
    ];

    const user = reactive({ error: false, data: {} }) as {
      error: boolean;
      data?: LanyardData;
    };

    const { send, close } = useWebSocket('wss://api.lanyard.rest/socket', {
      immediate: true,
      // Attempt to reconnect once (max)
      autoReconnect: {
        retries: 1,
        delay: 1000,
        onFailed() {
          user.error = true;
        },
      },
      // Send heartbeat every 30 seconds
      heartbeat: {
        message: JSON.stringify({
          op: 3,
        }),
        interval: 30000,
      },
      // Subscribe to the user WS is connected
      onConnected() {
        send(
          JSON.stringify({
            op: 2,
            d: {
              subscribe_to_id: userId,
            },
          }),
        );
        // socketLoaded.value = true;
      },
      // Set the reactive object to data from Lanyard
      onMessage(_, { data }) {
        const { t: type, d: response } = JSON.parse(data);

        if (['INIT_STATE', 'PRESENCE_UPDATE'].includes(type)) {
          user.data = response;
        }
      },
    });

    onBeforeRouteLeave(() => close());

    return { age, skills, user };
  },
});
</script>

<style lang="scss">
.tippy-box {
  @apply bg-gray-700 text-gray-100 rounded-md py-1 px-4;

  .tippy-svg-arrow {
    @apply text-gray-700;

    svg {
      @apply fill-current;
    }
  }
}
</style>

<style lang="scss" scoped>
.online {
  @apply bg-green-500;
}

.idle {
  @apply bg-yellow-500;
}

.dnd {
  @apply bg-red-500;
}

.offline {
  @apply bg-gray-300;
}
</style>
