import { createApp } from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck, faEnvelope, faEllipsisV, faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { faGithub, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import VueKinesis from 'vue-kinesis';
import VueTippy from 'vue-tippy';
import VueSmoothScroll from 'vue3-smooth-scroll';
import VueProgressBar from '@aacassandra/vue3-progressbar';

import 'devicon';
import 'tippy.js/themes/light-border.css';
import 'tippy.js/dist/svg-arrow.css';
import 'tippy.js/animations/scale.css';
import './assets/styles/index.scss';

import App from './App.vue';
import router from './router';
import store from './store';

library.add(faCheck, faEnvelope, faEllipsisV, faTimesCircle, faGithub, faInstagram, faLinkedin);

createApp(App)
  .use(store)
  .use(router)
  .use(VueKinesis)
  .use(VueTippy)
  .use(VueSmoothScroll, { updateHistory: false, offset: -50 })
  .use(VueProgressBar, { color: '#d4418e' })
  .component('icon', FontAwesomeIcon)
  .mount('#app');
