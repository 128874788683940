<template>
  <section id="tech">
    <h3 class="mb-10 text-center font-semibold relative">Tech Stack</h3>
    <div class="max-w-7xl mx-auto px-4">
      <div class="w-full lg:mb-0">
        <div class="grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-4 px-4">
          <kinesis-container
            v-for="tech in techStack"
            :key="tech.name"
          >
            <kinesis-element
              class="p-4 rounded-l-xl rounded-t-xl flex justify-around items-center bg-gray-800"
              tag="div"
              v-tippy="{ content: tech.name, arrow: roundArrow, delay: 100, animation: 'scale' }"
              :strength='5'
            >
              <programming-icon
                class="text-5xl"
                colored
                :icon='tech.icon'
              />
            </kinesis-element>
          </kinesis-container>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { roundArrow } from 'tippy.js';

import ProgrammingIcon from '@/components/programmingIcon.vue';

export default defineComponent({
  components: { ProgrammingIcon },
  data: () => ({
    roundArrow,
    techStack: [
      { name: 'HTML', icon: 'html5' },
      { name: 'JavaScript', icon: 'javascript' },
      { name: 'NodeJS', icon: 'nodejs' },
      { name: 'VueJS', icon: 'vuejs' },
      { name: 'CSS', icon: 'css3' },
      { name: 'SASS', icon: 'sass' },
      { name: 'TailwindCSS', icon: 'tailwindcss' },
      { name: 'Devicon', icon: 'devicon' },
      { name: 'Python', icon: 'python' },
      { name: 'Laravel', icon: 'laravel' },
      { name: 'MongoDB', icon: 'mongodb' },
      { name: 'MySQL', icon: 'mysql' },
      { name: 'PostgreSQL', icon: 'postgresql' },
      { name: 'GraphQL', icon: 'graphql' },
      { name: 'Docker', icon: 'docker' },
      { name: 'NGINX', icon: 'nginx' },
      { name: 'DigitalOcean', icon: 'digitalocean' },
      { name: 'Gitlab', icon: 'gitlab' },
    ],
  }),
});
</script>
