<template>
  <figure>
    <img :src="image">
    <figcaption>
      <p class="md:text-xl">{{ title }}</p>
      <p
        v-if="subTitle"
        class="md:text-xl"
      >{{ subTitle }}</p>
      <p class="text-sm text-gray-400"> {{ progress.text }}</p>
    </figcaption>
    <div
      v-if="spotify"
      :style="`transform: scaleX(${progress.percentage})`"
      class="progress"
    ></div>
  </figure>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import type { PropType } from 'vue';
import type { Timestamps } from '@/types/lanyard';
import { onBeforeRouteLeave } from 'vue-router';
import moment, { MomentInput } from 'moment';

export default defineComponent({
  props: {
    image: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: false,
    },
    spotify: {
      type: Boolean,
      default: false,
    },
    timestamps: {
      type: Object as PropType<Timestamps>,
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      default: () => ({ end: new Date(), start: new Date() }),
    },
  },
  setup(props) {
    const progress = reactive({ percentage: 0, text: '' });

    const spotifyInterval = () => {
      const now = moment(new Date());
      const [start, end] = [
        moment(props.timestamps.start),
        moment(props.timestamps.end),
      ];
      const [duration, played] = [end.diff(start), now.diff(start)];
      progress.percentage = played / duration;

      const format = (time: MomentInput) => moment(time).format('mm:ss');

      progress.text = `${format(played)} / ${format(duration)}`;
    };

    const playInterval = () => {
      const now = moment(new Date());
      const start = moment(props.timestamps.start);
      progress.text = moment(now.diff(start)).format('HH:mm:ss');
    };

    const interval = setInterval(
      props.spotify ? spotifyInterval : playInterval,
      1000,
    );

    onBeforeRouteLeave(() => clearInterval(interval));

    return { progress };
  },
});
</script>

<style lang="scss" scoped>
figure {
  @apply relative flex items-center gap-5;
  @apply w-full h-32 bg-gray-800 p-3 rounded-md overflow-hidden;

  .progress {
    @apply h-1 bg-gray-600 bg-opacity-80 absolute left-0 bottom-0 w-full origin-left;
  }

  img {
    @apply h-full rounded-md;
  }

  figcaption {
    @apply flex-grow overflow-hidden;

    p {
      @apply truncate leading-relaxed;
    }
  }
}

@screen md {
  figure {
    @apply h-32;
  }
}
</style>
