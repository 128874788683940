<template>
  <footer class="bg-gray-800">
    <div class="flex py-1 px-3 m-auto text-gray-300 flex-col md:flex-row max-w-6xl">
      <div class="flex items-center">
        <p class="text-sm">
          © 2021 All rights reserved.
          Made by
          <Link
            to="https://github.com/XanderD99"
            class="text-cerise-500"
          >XanderD99</Link> using
          <Link
            to="https://vuejs.org/"
            class="text-code-vuejs"
          >VueJS</Link> and
          <Link
            to="https://tailwindcss.com/"
            class="text-code-tailwindcss"
          >TailwindCSS</Link>.
          Inspired by
          <Link
            to="https://github.com/barbarbar338"
            class="text-purple-600"
          >barbarbar338</Link>.
        </p>
      </div>
      <div class="ml-auto flex items-center">
        <Link to="mailto:xander.denecker@gmail.com">
        <icon
          icon="envelope"
          class="inline-block w-6 h-6 mr-3 text-sapphire-300"
        />
        </Link>
        <Link to="https://github.com/XanderD99">
        <icon
          :icon="['fab', 'github']"
          class="inline-block w-6 h-6 mr-3 text-sapphire-300"
        />
        </Link>
        <Link to="https://www.instagram.com/denecker_xander/">
        <icon
          :icon="['fab', 'instagram']"
          class="inline-block w-6 h-6 mr-3 text-sapphire-300"
        />
        </Link>
        <Link to="https://www.linkedin.com/in/xanderdenecker/">
        <icon
          :icon="['fab', 'linkedin']"
          class="inline-block w-6 h-6 mr-3 text-sapphire-300"
        />
        </Link>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Link from '@/components/Link.vue';

export default defineComponent({
  components: { Link },
});
</script>
