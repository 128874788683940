
import { defineComponent, reactive } from 'vue';
import type { PropType } from 'vue';
import type { Timestamps } from '@/types/lanyard';
import { onBeforeRouteLeave } from 'vue-router';
import moment, { MomentInput } from 'moment';

export default defineComponent({
  props: {
    image: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: false,
    },
    spotify: {
      type: Boolean,
      default: false,
    },
    timestamps: {
      type: Object as PropType<Timestamps>,
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      default: () => ({ end: new Date(), start: new Date() }),
    },
  },
  setup(props) {
    const progress = reactive({ percentage: 0, text: '' });

    const spotifyInterval = () => {
      const now = moment(new Date());
      const [start, end] = [
        moment(props.timestamps.start),
        moment(props.timestamps.end),
      ];
      const [duration, played] = [end.diff(start), now.diff(start)];
      progress.percentage = played / duration;

      const format = (time: MomentInput) => moment(time).format('mm:ss');

      progress.text = `${format(played)} / ${format(duration)}`;
    };

    const playInterval = () => {
      const now = moment(new Date());
      const start = moment(props.timestamps.start);
      progress.text = moment(now.diff(start)).format('HH:mm:ss');
    };

    const interval = setInterval(
      props.spotify ? spotifyInterval : playInterval,
      1000,
    );

    onBeforeRouteLeave(() => clearInterval(interval));

    return { progress };
  },
});
