
import { defineComponent } from 'vue';
import siteList from '@/assets/json/siteList.json';

export default defineComponent({
  data: () => ({ open: false }),
  methods: {
    goToRandomSite() {
      window.open(siteList[Math.floor(Math.random() * siteList.length)]);
    },
  },
});
