
import { defineComponent, reactive } from 'vue';

import Footer from '@/components/Footer.vue';
import TechStack from '@/components/about/techStack.vue';
import CurrentActivities from '@/components/about/currentActivities.vue';
import ProgrammingIcon from '@/components/programmingIcon.vue';
import Navigation from '@/components/Navigation.vue';
import { onBeforeRouteLeave } from 'vue-router';
import { Activity, LanyardData } from '@/types/lanyard';
import { useWebSocket } from '@vueuse/core';

const userId = process.env.USER_ID || '355084177085038592';

export default defineComponent({
  components: {
    Footer,
    TechStack,
    CurrentActivities,
    Navigation,
    ProgrammingIcon,
  },
  setup() {
    const birthday = new Date('11/24/1999');
    const ageDate = new Date(Date.now() - birthday.getTime());
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);

    const skills = [
      'JavaScript (Node & Client-Side)',
      'Python',
      'NoSQL (MongoDB & FaunaDB)',
      'SQL (Postgre & Sqlite)',
    ];

    const user = reactive({ error: false, data: {} }) as {
      error: boolean;
      data?: LanyardData;
    };

    const { send, close } = useWebSocket('wss://api.lanyard.rest/socket', {
      immediate: true,
      // Attempt to reconnect once (max)
      autoReconnect: {
        retries: 1,
        delay: 1000,
        onFailed() {
          user.error = true;
        },
      },
      // Send heartbeat every 30 seconds
      heartbeat: {
        message: JSON.stringify({
          op: 3,
        }),
        interval: 30000,
      },
      // Subscribe to the user WS is connected
      onConnected() {
        send(
          JSON.stringify({
            op: 2,
            d: {
              subscribe_to_id: userId,
            },
          }),
        );
        // socketLoaded.value = true;
      },
      // Set the reactive object to data from Lanyard
      onMessage(_, { data }) {
        const { t: type, d: response } = JSON.parse(data);

        if (['INIT_STATE', 'PRESENCE_UPDATE'].includes(type)) {
          user.data = response;
        }
      },
    });

    onBeforeRouteLeave(() => close());

    return { age, skills, user };
  },
});
