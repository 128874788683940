
import { defineComponent } from 'vue';
import { roundArrow } from 'tippy.js';

import ProgrammingIcon from '@/components/programmingIcon.vue';

export default defineComponent({
  components: { ProgrammingIcon },
  data: () => ({
    roundArrow,
    techStack: [
      { name: 'HTML', icon: 'html5' },
      { name: 'JavaScript', icon: 'javascript' },
      { name: 'NodeJS', icon: 'nodejs' },
      { name: 'VueJS', icon: 'vuejs' },
      { name: 'CSS', icon: 'css3' },
      { name: 'SASS', icon: 'sass' },
      { name: 'TailwindCSS', icon: 'tailwindcss' },
      { name: 'Devicon', icon: 'devicon' },
      { name: 'Python', icon: 'python' },
      { name: 'Laravel', icon: 'laravel' },
      { name: 'MongoDB', icon: 'mongodb' },
      { name: 'MySQL', icon: 'mysql' },
      { name: 'PostgreSQL', icon: 'postgresql' },
      { name: 'GraphQL', icon: 'graphql' },
      { name: 'Docker', icon: 'docker' },
      { name: 'NGINX', icon: 'nginx' },
      { name: 'DigitalOcean', icon: 'digitalocean' },
      { name: 'Gitlab', icon: 'gitlab' },
    ],
  }),
});
