
import { defineComponent } from 'vue';

import ActivityCard from '@/components/ActivityCard.vue';
import type { Activity, LanyardData, Spotify } from '@/types/lanyard';

export default defineComponent({
  components: { ActivityCard },
  props: {
    user: {
      type: Object as () => LanyardData,
      require: true,
    },
  },
  computed: {
    spotify(): Spotify | null | undefined {
      return this.user?.spotify;
    },
    vscode(): Activity | undefined {
      return this.user?.activities?.find(
        (activity: Activity) => activity.name === 'Visual Studio Code',
      );
    },
  },
});
