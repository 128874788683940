
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    icon: {
      type: String,
      required: true,
    },
    wordmark: {
      type: Boolean,
      default: false,
    },
    line: {
      type: Boolean,
      default: false,
    },
    colored: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    let className = `devicon-${props.icon}-plain`;
    if (props.line) className.replace('plain', 'line');
    if (props.wordmark) className += '-wordmark';

    return { className: `${className} ${props.colored && 'colored'}` };
  },
});
