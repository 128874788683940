import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"




export default _defineComponent({
  props: {
  to: { type: String, required: true },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock("a", {
    href: __props.to,
    rel: "noreferrer",
    target: "_blank",
    class: "hover:underline"
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 8, ["href"]))
}
}

})