/* eslint-disable max-len */
<template>
  <!--eslint-disable-->
  <div class="w-full h-screen flex items-center justify-center p-5 lg:p-20 bg-gradient-to-br from-sapphire-700 to-cerise-800">
    <kinesis-container>
      <kinesis-element
        type="depth"
        :strength="3"
        class="bg-gray-800 rounded-xl shadow-2xl border-2 border-gray-600"
      >
        <div class="p-4 flex gap-2">
          <span
            @click="$router.go(-1)"
            class="h-4 w-4 bg-red-500 rounded-full block cursor-pointer"
          ></span>
          <span
            @click="$router.go(-1)"
            class="h-4 w-4 bg-yellow-500 rounded-full block cursor-pointer"
          ></span>
          <span class="h-4 w-4 bg-green-500 rounded-full block cursor-pointer"></span>
        </div>
        <div class="px-4 md:px-8 py-3 md:pb-8 w-full flex flex-col gap-2">
          <div class="flex gap-3 break-all">
            <p>
              <icon
                icon="times-circle"
                class="text-red-500 bg-white rounded-full text-sm align-baseline"
              />
            </p>
            <p class="text-red-400"><span>&#9205;</span> GET <span class="underline">{{ url }}</span> 404</p>
          </div>
          <div class="flex gap-3">
            <p>
              <icon
                icon="times-circle"
                class="text-red-500 bg-white rounded-full mt-2 text-sm align-baseline"
              />
            </p>
            <div class="break-all">
              <p class="text-red-400"><span>&#9205;</span> Uncaught (in promise) Error: <span class="underline">createError.js</span> Request failed with status code 404</p>
              <p class="ml-6 md:ml-12 text-red-400">at createError (<span class="underline">createError.js?2d83:32</span>)</p>
              <p class="ml-6 md:ml-12 text-red-400">at webstite (<span class="underline">website.js?467f:80</span>)</p>
              <p class="ml-6 md:ml-12 text-red-400">at XMLHttpRequest.handleload (<span class="underline">xhr.js?b50d:61</span>)</p>
            </div>
          </div>
        </div>
      </kinesis-element>
    </kinesis-container>

    <p class="absolute bottom-0 left-0 p-2 opacity-60 text-sm">{{ helpText }}</p>
  </div>
  <!--eslint-enable-->
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  data: () => ({ url: window.location.href, helpText: '' }),
  mounted() {
    // eslint-disable-next-line no-return-assign
    setTimeout(() => {
      this.helpText = 'Click the red or orange button to return to your previous page';
    }, 10000);
  },
});
</script>

<style lang="scss" scoped>
p {
  @apply break-words;

  span {
    @apply text-gray-400;
  }
}
</style>
