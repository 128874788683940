
import { defineComponent } from 'vue';

export default defineComponent({
  data: () => ({ url: window.location.href, helpText: '' }),
  mounted() {
    // eslint-disable-next-line no-return-assign
    setTimeout(() => {
      this.helpText = 'Click the red or orange button to return to your previous page';
    }, 10000);
  },
});
