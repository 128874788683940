<template>
  <!-- eslint-disable -->
  <section>
    <h3 class="mb-10 text-center font-semibold"><span class="text-cerise">Current</span> Activities</h3>
    <div class="max-w-6xl mx-auto px-4">
      <div class="grid md:grid-cols-2 gap-5">
        <activity-card
          v-if="spotify"
          :title="spotify.artist"
          :subTitle="spotify.song"
          :image="spotify.album_art_url"
          :timestamps="spotify.timestamps"
          spotify
        />
        <activity-card
          v-if="vscode"
          :key="vscode.name"
          :title="vscode.name"
          :subTitle="`${vscode.details} (${vscode.state})`"
          :timestamps="vscode.timestamps"
          :image="`https://cdn.discordapp.com/app-assets/383226320970055681/${vscode?.assets?.large_image}.png`"
        />
      </div>
    </div>
  </section>
  <!-- eslint-enable -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import ActivityCard from '@/components/ActivityCard.vue';
import type { Activity, LanyardData, Spotify } from '@/types/lanyard';

export default defineComponent({
  components: { ActivityCard },
  props: {
    user: {
      type: Object as () => LanyardData,
      require: true,
    },
  },
  computed: {
    spotify(): Spotify | null | undefined {
      return this.user?.spotify;
    },
    vscode(): Activity | undefined {
      return this.user?.activities?.find(
        (activity: Activity) => activity.name === 'Visual Studio Code',
      );
    },
  },
});
</script>
